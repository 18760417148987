import React from "react";
import Meta from "../components/Meta";
import Item from "../components/list/Item";
import Button from "../components/button/Button";

const Home = () => {
  return (
    <div>

      <Meta
        title="Engineering Book Club - Online community for reading books"
        desc="The Engineering Book Club is an online community for professional that wants to read books together."
      />

      <div className="pt-[5.5rem] lg:pt-24">
        <section className="dark:bg-jacarta-800 relative py-10">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
              src="/images/gradient_light.jpg"
              alt="gradient"
              className="h-full w-full"
            />
          </picture>
          <div className="container">
            <div className="lg:flex lg:items-center lg:justify-between">
              {/* <!-- Info --> */}
              <div className="pb-10 lg:w-[45%] lg:py-20 lg:pr-16">
                <h1 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                  Engineering Book Club
                </h1>
                <p className="dark:text-jacarta-60 mb-10 text-lg leading-normal">
                  An online community of professionals that enjoy reading and discussing engineering books together.
                </p>

                <p className="dark:text-jacarta-60 mb-10 leading-normal">
                  Become a member of our community and start reading books with us. Not Credit Card required.
                </p>

                <Button title="Join Now" link="/sign-up"/>

                {/*<NewsletterForm subscriptionList="892LQR763mQFYPM1763o763NqvT3Qw"/>*/}

              </div>

              {/* <!-- Image --> */}
              <div className="lg:w-[55%]">
                <div className="relative">
                  <img
                    alt="engineering books"
                    src="/images/homepage/engineering-books.png"
                    className="mx-auto mt-8 w-[80%] rotate-[8deg] rounded-[10rem]"
                  />
                  <img
                    src="/images/hero/3D_elements.png"
                    alt="decoration for professional books"
                    className="animate-fly absolute top-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-10">
          <div className="container">
            <div className="justify-between lg:flex lg:space-x-20">

              <div className="lg:w-[55%]">
                <div className="mt-12 md:flex md:space-x-8 lg:justify-end">
                  <div className="relative mb-8 max-w-[13.125rem] self-end rounded-2.5xl bg-accent-dark p-8 shadow-2xl">
                    <img
                      src="/images/patterns/pattern_circle_1.png"
                      className="absolute -top-10 -left-8 -z-10 animate-fly dark:z-0 dark:opacity-10"
                      alt="coding books"
                    />
                    <div>
                    <span className="mb-4 block font-display text-base text-white">
                      Learn and Grow
                    </span>
                      <span className="block text-base text-white">
                      investing in self-education
                    </span>
                    </div>
                  </div>

                </div>
                <div className="relative">
                  <img
                    src="/images/patterns/pattern_circle_2.png"
                    className="absolute -bottom-8 right-12 animate-fly dark:opacity-10"
                    alt="book club decoration pattern"
                  />
                  <div className="relative mx-auto max-w-xs self-start rounded-2.5xl bg-accent p-8 shadow-2xl">
                    <div className="absolute right-8 top-8 rounded-full bg-white p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-blue"
                      >
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path
                          d="M4.406 14.523l3.402-3.402 2.828 2.829 3.157-3.157L12 9h5v5l-1.793-1.793-4.571 4.571-2.828-2.828-2.475 2.474a8 8 0 1 0-.927-1.9zm-1.538 1.558l-.01-.01.004-.004A9.965 9.965 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10c-4.07 0-7.57-2.43-9.132-5.919z"/>
                      </svg>
                    </div>

                    <div className="text-left">
                    <span className="mb-4 block font-display text-base text-white">
                      Open for Everyone
                    </span>
                      <span className="block text-base text-white">
                      it does not matter how experienced you are
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-10 py-10 lg:pr-25">
                <h2 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                  A Community for Everyone
                </h2>
                <p className="dark:text-jacarta-60 mb-10 leading-normal">
                  If you are a professional working in the Software Industry, <b>this is a community for you</b>. This is not
                  only a book club, this is a place where everyone learn together from each other. The books are the perfect
                  excuse for creating debates around topics.
                </p>
                <Item title="Students and recently hired professionals" description="You are a student, just being hired or in the early stage of your career and you want to learn from more experienced professionals."/>
                <Item title="Experienced Individual Contributors" description="You have been already working for years but you are looking for other opportunities to keep learning new stuff."/>
                <Item title="Engineering Managers and Leaders" description="You are in a managing or leading position and want to bring your skills to the next level."/>
              </div>
            </div>
          </div>
        </section>

        <section className="dark:bg-jacarta-800 relative py-5">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
              src="/images/gradient_light.jpg"
              alt="gradient"
              className="h-full w-full"
            />
          </picture>

          <div className="container">
            <div className="lg:flex lg:justify-between">
              {/* <!-- Info --> */}
              <div className="py-10 lg:w-[45%] ">
                <h2 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                  How the Book Club Works
                </h2>
                <p className="dark:text-jacarta-60 mb-8 leading-normal">
                  By joining <b>The Engineering Book Club</b>, you will be part of an online community of professionals.
                  It is a private space that we use to share our thoughts and ideas and debate in a safe environment.
                </p>
                <p className="dark:text-jacarta-60 mb-8 leading-normal">
                  The books that we read are selected by the community. We have a section where everyone shares their
                  thoughts about the book asynchronously. We also schedule every two weeks a virtual coffee to join everyone
                  together and chat about the book.
                </p>
                <Item title="Professional Network" description="Connect with other professionals with similar interests."/>
                <Item title="Acquire New Skills" description="Read engineering books while sharing your thoughts."/>
                <Item title="Growth" description="Grow professionally alongside other talented professionals."/>

              </div>

              {/* <!-- Image --> */}
              <div className="pt-20 lg:w-[55%]">
                <div className="relative">
                  <img
                    src="/images/homepage/online-community.png"
                    width="600"
                    height="200"
                    className="mx-auto mt-8 w-[80%] "
                  />
                </div>
              </div>


            </div>
          </div>
        </section>

        <section className="relative py-10">
          <div className="container">
            <div className="justify-between lg:flex lg:space-x-20">

              <div className="lg:w-[55%]">
                <h2 className="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
                  We are currently voting for the next book. Perfect moment to join us!
                </h2>
                {/*<h3 className="text-jacarta-700 font-display text-xl dark:text-white">*/}
                {/*  Grokking Concurrency*/}
                {/*</h3>*/}
                {/*<p className="dark:text-jacarta-60 leading-normal">*/}
                {/*  Book by Kiril Bobrov*/}
                {/*</p>*/}
                {/*<h3 className="text-jacarta-700 font-display mt-10 text-xl dark:text-white">*/}
                {/*  System Design Interview*/}
                {/*</h3>*/}
                {/*<p className="dark:text-jacarta-60 mb-10 leading-normal">*/}
                {/*  Book by Alex Xu*/}
                {/*</p>*/}

              </div>
              <div className="lg:w-[45%]">
                <div className="relative">
                  <img
                    alt="A Philosophy of Software Design by John Ousterhout"
                    src="/images/homepage/new-books-01.png"
                    width="300"
                    height="300"
                    className="mx-auto mt-5 w-[90%] rotate-[8deg] rounded-[10rem]"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="mx-auto max-w-2xl pt-24 text-center">
              <Button title="Join Us Now" link="/sign-up"/>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
